const BASE_URL = 'https://api.indiafastservices.com/api/v1/admin/' // Your API base URL

// const BASE_URL = 'http://localhost:3001/api/v1/admin/' // Your API base URL

const handleResponse = async (response) => {
  if (!response.ok) {
    const error = await response.json()
    throw new Error(error.message)
  }
  return response.json()
}

const apiSuperService = {
  async get(endpoint) {
    try {
      const token = localStorage.getItem('adminToken')
      const tokenWithoutQuotes = token.replace(/"/g, '')
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        headers: {
          Authorization: 'Bearer ' + tokenWithoutQuotes,
        },
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      return handleResponse(response)
    } catch (error) {
      // Handle server or network issues here
      return 'Error:', error.message
    }
  },

  async getNotoken(endpoint) {
    try {
      const response = await fetch(`${BASE_URL}${endpoint}`)

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      return handleResponse(response)
    } catch (error) {
      // Handle server or network issues here
      return 'Error:', error.message
    }
  },

  async post(endpoint, data, other) {
    if (other) {
      const token = localStorage.getItem('adminToken')
      const tokenWithoutQuotes = token.replace(/"/g, '')
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + tokenWithoutQuotes,
        },
        body: JSON.stringify(data),
      })
      return handleResponse(response)
    } else {
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      return handleResponse(response)
    }
  },

  // Add more methods (e.g., put, delete) as needed
}

export default apiSuperService
