const BASE_URL = 'https://api.indiafastservices.com/api/v1/retailer/'
// const BASE_URL = 'http://localhost:3001/api/v1/retailer/' // Your API base URL
const verifyApiURL = 'https://uat.paysprint.in/sprintverify-uat/api/v1/' // Your API base URL for verification

const handleResponse = async (response) => {
  if (!response.ok) {
    const error = await response.json()
    throw new Error(error.message)
  }
  return response.json()
}

const apiAdminService = {
  async get(endpoint) {
    try {
      const token = localStorage.getItem('token')
      const tokenWithoutQuotes = token.replace(/"/g, '')
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        headers: {
          Authorization: 'Bearer ' + tokenWithoutQuotes,
        },
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      return handleResponse(response)
    } catch (error) {
      return 'Error:', error.message
    }
  },

  async getBlob(endpoint) {
    try {
      const token = localStorage.getItem('token')
      const tokenWithoutQuotes = token.replace(/"/g, '')
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        headers: {
          Authorization: 'Bearer ' + tokenWithoutQuotes,
        },
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      return response.blob()
    } catch (error) {
      console.error('Error:', error.message)
      return 'Error:', error.message
    }
  },

  async getwithoutToken(endpoint) {
    const response = await fetch(`${BASE_URL}${endpoint}`)
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }
    return handleResponse(response)
  },

  async getaadharOtp(token, endpoint, refid, id_number) {
    try {
      let data = JSON.stringify({
        refid: refid,
        id_number: id_number,
      })
      const response = await fetch(`${verifyApiURL}${endpoint}`, {
        method: 'POST',
        headers: {
          Token: token,
          authorisedkey: 'TVRJek5EVTJOelUwTnpKRFQxSlFNREF3TURFPQ==',
          'Content-Type': 'application/json',
        },
        body: data,
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      return handleResponse(response)
    } catch (error) {
      return 'Error:', error.message
    }
  },

  async getNotoken(endpoint) {
    try {
      const response = await fetch(`${BASE_URL}${endpoint}`)

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      return handleResponse(response)
    } catch (error) {
      return 'Error:', error.message
    }
  },

  async post(endpoint, data, other) {
    if (other) {
      const token = localStorage.getItem('token')
      const tokenWithoutQuotes = token.replace(/"/g, '')
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + tokenWithoutQuotes,
        },
        body: JSON.stringify(data),
      })
      return handleResponse(response)
    } else {
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      return handleResponse(response)
    }
  },

  async postForm(endpoint, data) {
    try {
      const token = localStorage.getItem('token')
      const tokenWithoutQuotes = token.replace(/"/g, '')
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + tokenWithoutQuotes,
        },
        body: data,
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      return await response.json()
    } catch (error) {
      return { status: 2, message: error.message }
    }
  },
}

export default apiAdminService
